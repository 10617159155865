<template lang="html">
    <div>
        <section class="content-header">
            <div class="container-fluid">
                <div class="mb-2">
                    公共管理
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'

    export default {
        data() {
            return {
                list: null,
                loading: false
            }
        },
        created() {
            //this.getUser()
            //this.setPageInfo("主页")
        //    this.loadData()
        },
        computed: {
            ...mapGetters([
            ]),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions([
                //'getUser',
                // 'setPageInfo'
            ]),
            loadData() {
                var _this = this;
                this.loading = true;
                var config = {
                    params: {
                        category: '',
                        page: 1,
                        pageSize: 10,
                    }
                };
                this.$axios.get('/Api/InnAdmin/InnInfos', config).then(function (res) {
                    //console.log(res.data)
                    _this.list = res.data.data
                    _this.loading = false;
                }).catch(e => {
                    _this.loading = false;
                })

            },
        },
        components: {
        }
    }
</script>

<style lang="css">
</style>
